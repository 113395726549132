<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>Transférer la propriété</v-toolbar-title>
    </v-toolbar>
    <template v-slot:activator="bind">
      <slot name="activator" v-bind="bind" />
    </template>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar title="Transférer la propriété" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-5">
        <form-category title="Choix d'utilisateur" subtitle="Choisir un membre pour le transfert de propriété" />
        <team-members-list v-model="membership" :team="team" hide-me selectable class="card-modal" />

        <v-row class="text-center justify-center my-4">
          <v-btn color="primary" x-small :loading="transferringOwnership" :disabled="transferringOwnership" @click="transferOwnership">
            Confirmer
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import TeamMembersList from '@/modules/teams/components/Members/TeamMembersList'
  import {Team} from '@/models'

  export default {
    name: 'TeamTransferOwnershipDialog',
    components: {TeamMembersList, ModalsSidebar, FormCategory},
    props: {
      team: Team
    },
    data() {
      return {
        membership: null,
        transferringOwnership: false
      }
    },
    methods: {
      async transferOwnership() {
        try {
          this.transferringOwnership = true
          await this.team.transferOwnership(this.membership)

          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Groupe transféré',
            subtitle: 'La propriété du groupe a été transféré avec succès.'
          })
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'La propriété du groupe n\'a pas pu être transféré.'
          })
          throw e
        } finally {
          this.transferringOwnership = false
          this.$emit('close')
        }
      }
    }
  }
</script>
